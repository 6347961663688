import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class Swipers {
    constructor(options = null) {}

    init() {
        this.__swiper1();
        this.__gallerySwiper();
        this.__quoteSwiper();
    }

    __swiper1() {
        const $swiperBlocks = $(".swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 2,
                spaceBetween: 22.5,
                autoplay: false,
                loop: false,
                grabCursor: true,
                navigation: {
                    nextEl: ".swiper-next." + swiperBlockId,
                    prevEl: ".swiper-prev." + swiperBlockId,
                },
            });
        }
    }
    __gallerySwiper() {
        const $swiperBlocks = $(".gallery_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            // Get the total number of slides
            const totalSlides = $(swiperBlock).find(".swiper-slide").length;

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                loop: true,
                slidesPerView: 1,
                spaceBetween: 15,
                grabCursor: true,
                breakpoints: {
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                    },
                    768: {
                        slidesPerView: totalSlides >= 3 ? 3 : totalSlides,
                        spaceBetween: 15,
                    },
                    992: {
                        slidesPerView: totalSlides >= 4 ? 4 : totalSlides,
                        spaceBetween: 15,
                    },
                    1200: {
                        slidesPerView: totalSlides >= 6 ? 6 : totalSlides,
                        spaceBetween: 15,
                    },
                },
                autoplay: {
                    disableOnInteraction: false, // Autoplay continues even when the user interacts with the swiper
                },
            });
            if (totalSlides > swiper.params.slidesPerView) {
                swiper.autoplay.start(); // Start autoplay if the total slides is greater than the slides per view
            }
        }
    }
    __quoteSwiper() {
        const $swiperBlocks = $(".quote_swiper_block");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 15,
                autoplay: true,
                delay: 4000,
                loop: true,
                grabCursor: true,
                navigation: {
                    nextEl: ".swiper-next." + swiperBlockId,
                    prevEl: ".swiper-prev." + swiperBlockId,
                },
            });
        }
    }
}
